<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="admissionRoundId"
              >
                <v-select
                  v-model="filter.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  placeholder="Đợt xét tuyển"
                  @input="readAdmissionForm"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group
                label-for="admissionFormId"
              >
                <v-select
                  id="admissionFormId"
                  v-model="filter.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                  placeholder="Phương thức xét tuyển"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mr-1"
                    @click="btnSearchClick()"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="align-text-top"
                    />
                    Tìm kiếm
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.saveModal
                    variant="success"
                    size="sm"
                    class=""
                    @click="onCreate"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col cols="12">
              <vue-good-table
                mode="remote"
                class="my-table"
                row-style-class="vgt-row"
                style-class="vgt-table striped bordered"
                :columns="columns"
                :rows="admissionConfirmationCertificateAccept"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>
                  <span
                    v-else-if="props.column.field === 'action'"
                    class="thead-group-action"
                  >
                    <b-button-group>
                      <b-button
                        v-if="updatable"
                        v-b-modal.saveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onEdit(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        size="sm"
                        @click="onDelete(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-button-group>
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center ml-1 mb-1 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-1 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <Save
      ref="saveModalRef"
      :item="currentAdmissionLanguageScore"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Save from '@/views/admission/admission-confirmation-certificate-accept/Save.vue'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
    Save,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        admissionRoundId: null,
        admissionFormId: null,
        sort: '',
      },
      currentAdmissionLanguageScore: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      totalRows: 'admissionConfirmationCertificateAccept/totalRows',
      statuses: 'admissionRound/statuses',
      admissionConfirmationCertificateAccept: 'admissionConfirmationCertificateAccept/admissionConfirmationCertificateAccepts',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
    }),
    columns() {
      return [
        {
          label: 'STT',
          field: 'rowNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Phương thức xét tuyển',
          field: 'admissionFormName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Bộ tiêu chí phụ thuộc',
          field: 'criteriaSetName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tiêu chí phụ thuộc',
          field: 'criteriaName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Loại đối tượng',
          field: 'confirmationCertificateTypeName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            placeholder: 'Tất cả',
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    statusFilterDropdownItems() {
      return this.statuses.map(status => ({ value: status.value, text: status.label }))
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPT)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPT)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPT)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization()
      await this.read(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      read: 'admissionConfirmationCertificateAccept/read',
      delete: 'admissionConfirmationCertificateAccept/delete',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({admissionRoundId: id})
    },
    async btnSearchClick() {
      this.isLoading = true
      try {
        await Promise.all([
          this.read(this.filter),
        ])
      } catch ( e ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreate() {
      this.currentAdmissionLanguageScore = undefined
    },
    onEdit(admissionLanguageScore) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = admissionLanguageScore
      this.currentAdmissionLanguageScore = rest
    },
    onDelete(admissionLanguageScore) {
      this.$swal({
          title: `Bạn chắc chắn muốn xóa điểm của chứng chỉ <span class="text-danger">${admissionLanguageScore.languageCertificateTypeName}</span>?`,
          text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if ( result.value ) {
            this.isLoading = true
            try {
              const response = await this.delete(admissionLanguageScore.id)
              if ( response ) {
                const {
                  isSuccessful,
                  message
                } = response
                if ( isSuccessful ) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  try {
                    await this.read(this.filter)
                  } catch ( e ) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `[${e.code}] ${e.message}`,
                        icon: 'XCircleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch ( e ) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataFromStore()
    },
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.read(this.filter)
      } catch ( e ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({
        currentPage: param.currentPage,
        itemsPerPage: param.currentPerPage
      })
      await this.getDataFromStore()
    },
    async onSortChange(param) {
      if ( param.length > 0 ) {
        const {
          field,
          type
        } = param[0]
        if ( field === 'name' ) this.updateParams({ sort: `1_${type}` })
        else if ( field === 'code' ) this.updateParams({ sort: `2_${type}` })
        else if ( field === 'orderNo' ) this.updateParams({ sort: `3_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if ( Object.hasOwn(columnFilters, 'status') ) this.updateParams({ status: columnFilters.status })
      await this.getDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getDataFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
